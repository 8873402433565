.accept-infoCallout .pb-Callout__icon {
  color: #767676 !important;
}

.loan-info {
  list-style: none;
  margin: 0 0 10px 0;
  padding: 0;
  overflow: auto;
}

.loan-info__title {
  font-weight: bold;
  display: block;
}

.loan-info li {
  margin-bottom: 10px;
}

@media screen and (min-width: 600px) {
  .loan-info li {
    width: 50%;
    float: left;
  }
  .loan-info li:nth-child(even) {
    float: right;
  }
}
