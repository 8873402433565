.loanMasthead {
  background-color: #edf4e9;
  overflow: hidden;
  padding: 16px;
  margin-bottom: 24px;
}

.loanMasthead__inner {
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.loanMasthead__icon {
  display: none;
}

.loanMasthead__description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loanMasthead__description h3 {
  font-weight: 400;
}

@media screen and (min-width: 600px) {
  .loanMasthead__icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 24px;
  }

  .loanMasthead {
    margin-bottom: 32px;
  }
}
