.acceptMasthead {
  background-color: #edf4e9;
  overflow: auto;
  padding: 16px;
  margin-bottom: 24px;
}

.acceptMasthead__inner {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 10px;
}

.acceptMasthead__description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.acceptMasthead__description h3 {
  font-weight: 400;
}

@media screen and (min-width: 500px) {
  .acceptMasthead__inner {
    margin-bottom: 38px;
  }
}

@media screen and (min-width: 1056px) {
  .acceptMasthead {
    margin-bottom: 48px;
  }
}
