.loansCalcInfo .pb-Callout__icon {
  color: #767676 !important;
}

.pb-Grid__Cell #loanCalculator {
  margin-bottom: 40px;
}

.pb-Grid__Cell #representativeExample {
  margin-bottom: 16px;
}

.loanDuration__Playback {
  margin-bottom: 8px;
}
#loanCalculator .pb-RangeInput__RangeInput {
  width: calc(100% - 40px);
  margin-left: 20px;
}

@media screen and (max-width: 960px) {
  .pb-Grid__Cell #link {
    margin-bottom: 24px;
  }
}


#representativeExample .pb-PlaybackList__PlaybackValue {
  font-size: 18px;
}
@media screen and (min-width: 960px) {
  #representativeExample .pb-PlaybackList__PlaybackValue {
    font-size: 20px;
  }

  .loansCalculator {
    border: 1px solid #ccc;
    padding: 24px;
  }

  .pb-Grid__Cell #loanCalculator {
    margin-bottom: 32px;
  }

  .pb-Grid__Cell #representativeExample {
    margin-bottom: 32px;
  }

  .pb-Grid__Row #additionalInfo {
    display: block;
  }
  #loanCalculator .pb-RangeInput__RangeInput {
    width: 100%;
    margin-left: 0;
  }
}
