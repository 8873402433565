.pb-ProgressIndicator__indicator {
  background-color: #4d8210 !important;
}

.pb-Footer__Footer {
  text-align: left;
}

.loanDuration__Playback {
  background: #f3f3f3;
  display: inline-block;
  line-height: 2.5;
  /* display: flex; */
  width: 50%;
  margin-right: 30px;
  text-align: center;
}

/* to integrate into PB loans calculator*/
.pb-RangeInput__trackLabelText {
  left: 0;
}

.pb-RangeInput__trackProgress {
  background: #4d8210;
}

.pb-InputPlayback__playbackSlot {
  flex: 0 0 50%;
}

.pb-RangeInput__tick::after {
  border-right: 1px solid #f2f2f2;
}

/* ORL-2479 temp fix whilst awaiting PIGGY-504 */
legend {
  width: 100%;
}

/* Accessibility helper */
.sr-only {
  border: 0; 
  clip: rect(0 0 0 0); 
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  height: 1px; 
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  text-indent: -5000px;
  visibility: hidden;
}